import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import HowToModal from '../components/HowToModal/HowToModal';
import Reward from '../components/Reward';
import ImagesSet from '../components/ImagesSet';
import { addStag, toSrcSet } from '../utils/utils';
import FooterVariantA from '../components/FooterVariantA/FooterVariantA';
import WheelTitle from '../components/WheelTitle/WheelTitle';
import LogoCenter from '../components/LogoCenter/LogoCenter';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import Wheel from '../components/Wheel/Wheel';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  html, body {
    margin: 0;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
  }
  body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    background: #0C111B;
    background: radial-gradient(103.54% 124.25% at 0% 0%, #213152 0%, #05080E 100%);
    color: #838FA3;
    overflow-x: hidden;
    min-width: 100%;
    min-height: 100%;
  }
  
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #303D58;
  }

  ::-webkit-scrollbar-track {
    background: #0C111B;
  }
  
  img {
    user-drag: none;
    user-select: none;
  }
  
  .no-scroll {
    overflow-y: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const WheelContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 600px) {
    height: calc(100vw + 250px);
  }
`;

const WheelWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 650px) {
    padding: 75px 0 0 0;
  }
  @media screen and (max-width: 600px) {
    position: absolute;
    left: -20vw;
    width: 140vw;
    height: 140vw;
  }
`;

const WheelContainerTitle = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
`;

const PlayerLeft = styled.div`
  position: absolute;
  z-index: 8;
  left: calc(50% - 564px);
  bottom: 0;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const PlayerRight = styled.div`
  position: absolute;
  z-index: 8;
  left: calc(50% + 238px);
  bottom: 0;
  
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

const PlayerOverlay = styled.div`
  position: absolute;
  background: rgb(5,8,14);
  background: linear-gradient(0deg, rgba(33,49,82,0.7) 0%, rgba(33,49,82,0.3) 35%, transparent 100%);
  z-index: 9;
  width: 100%;
  height: 100%;
`;

const LP2 = ({ pageContext }) => {
  const [win, setWin] = useState(null);
  const [bonusInfo, setBonusInfo] = useState(false);

  const {
    title,
    subTitle,
    promoUrl,
    company = "Sportbet.one",
    footerText,
    copyright,
    termsAndConditionsTitle,
    termsApplyButtonText,
    showTermsAndConditions,
    termsAndConditions,
    playerLeft,
    playerRight,
    titleEmoji
  } = pageContext;

  const img1 = playerLeft && toSrcSet(playerLeft);
  const img2 = playerRight && toSrcSet(playerRight);

  useEffect(() => {
    if (typeof document === "undefined") return;
    const linkClick = (e) => {
      const href = e.currentTarget.href;
      if (href.includes("promo=")) {
        e.preventDefault();
        window.location.href = addStag(href);
      }
    };

    document.querySelectorAll("a").forEach(x => x.addEventListener("click", linkClick));
    return () => {
      document.querySelectorAll("a").forEach(x => x.removeEventListener("click", linkClick));
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Sportbet.one</title>
      </Helmet>
      <GlobalStyle />
      {!bonusInfo && win ? <Reward pageContext={pageContext} win={win} onClose={() => { setWin(null); }} openBonus={() => setBonusInfo(true)} /> : null}
      {bonusInfo && win ? <HowToModal win={win} onClose={() => { setBonusInfo(false); }} {...pageContext} /> : null}
      <Container>
        <LogoCenter promoUrl={promoUrl} />
        <WheelContainer>
          <PlayerLeft>
            {img1 ? <ImagesSet {...img1} /> : null}
            <PlayerOverlay />
          </PlayerLeft>
          <PlayerRight>
            {img2 ? <ImagesSet {...img2} /> : null}
          </PlayerRight>
          <WheelContainerTitle>
            <WheelTitle title={title} subTitle={subTitle} titleEmoji={titleEmoji} />
          </WheelContainerTitle>
          <WheelWrapper>
            <Wheel setWin={setWin} {...pageContext} />
          </WheelWrapper>
        </WheelContainer>
        <FooterVariantA
          showTermsAndConditions={showTermsAndConditions}
          termsAndConditionsTitle={termsAndConditionsTitle}
          company={company}
          footerText={footerText}
          copyright={copyright}
          termsAndConditions={termsAndConditions}
          termsApplyButtonText={termsApplyButtonText}
        />
      </Container>
    </>
  );
};

export default LP2;
