import React from "react";
import styled from "styled-components";
import hand from "./hand.png";

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const TitleImage = styled.img`
  ${props => props.mirrored ? `transform: scale(-1, 1);` : ""}
`;

const TitleText = styled.span`
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #FFFFFF;
  @media screen and (max-width: 650px) {
    font-size: 28px;
    line-height: 35px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media screen and (max-width: 440px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
  @media screen and (max-width: 650px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
  @media screen and (max-width: 440px) {
    font-size: 16px;
  }
`;

const WheelTitle = ({ title, subTitle, titleEmoji }) => (
  <TitleContainer>
    <Title>
      <TitleImage src={titleEmoji?.file?.url || hand} />
      <TitleText>{title}</TitleText>
      <TitleImage src={titleEmoji?.file?.url || hand} mirrored />
    </Title>
    <SubTitle>{subTitle}</SubTitle>
  </TitleContainer>
);

export default WheelTitle;
