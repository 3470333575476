import React from "react";
import styled from "styled-components";
import logo from "./logo.svg";

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  
  margin: 46px 0;
  
  img {
    width: 328px;
  }
`;

const LogoCenter = ({ promoUrl }) => (
  <LogoContainer>
    <img src={logo} />
  </LogoContainer>
);

export default LogoCenter;
