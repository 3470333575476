import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import glare from "./glare.png";
import bg from "./bg.png";
import ellipse from "./ellipse.png";
import spinButton from "./spin.svg"

const WheelContainer = styled.div`
  font-family: Roboto;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WheelCaption = styled.div`
  font-size: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  color: #FFFFFF;
  padding-left: 50px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-weight: bold;
  user-select: none;

  @media screen and (max-width: 900px) {
    padding-left: 6vw;
    font-size: 18px;
  }
  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 8vw;
    font-size: 18px;
  }
  @media screen and (max-width: 470px) {
    padding-left: 10vw;
    font-size: 14px;
  }
  @media screen and (max-width: 390px) {
    padding-left: 7vw;
  }
  @media screen and (max-width: 350px) {
    padding-left: 9vw;
  }
  @media screen and (max-width: 330px) {
    font-size: 13px;
    padding-left: 10vw;
  }
`;

const WheelCaptionSum = styled.div`
  font-size: 2em;
  text-align: center;
  width: 120px;

  @media screen and (max-width: 900px) {
    width: 14vw;
  }
  @media screen and (max-width: 600px) {
    width: 18vw;
  }
  @media screen and (max-width: 390px) {
    width: 23vw;
  }
`;

const WheelCaptionDescription = styled.div`
  font-size: 1em;
  @media screen and (max-width: 390px) {
    width: 23vw;
  }
`;

const WheelCaptionTryAgain = styled.div`
  font-size: 1.2em;
  text-align: right;

  @media screen and (max-width: 390px) {
    font-size: 1em;
    padding-left: 4vw;
  }
  @media screen and (max-width: 350px) {
    text-align: center;
    max-width: 10vw;
    font-size: 1.2em;
    padding-left: 2vw;
  }
`;

const WheelSectors = styled.div`
  width: 60%;
  height: 60%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const WheelSectorA = styled.div`
  width: 50%;
  height: 50%;
  background: #F2850F;
  background: linear-gradient(45deg,#FFC253 0%,#F2850F 100%);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.12), inset 0px 20px 20px rgba(255, 255, 255, 0.12);
  left: 50%;
  top: 0;
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${WheelCaption} {
    transform: rotate(-45deg);
  }
`;

const WheelSectorB = styled.div`
  width: 50%;
  height: 50%;
  background: #82D0F5;
  background: linear-gradient(167deg,#82D0F5 30%,#02B6ED 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12), inset 0px -20px 20px rgba(255, 255, 255, 0.12);
  left: 50%;
  top: 50%;
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;

  ${WheelCaption} {
    transform: rotate(45deg);
  }
`;

const WheelSectorC = styled.div`
  width: 50%;
  height: 50%;
  background: #0055FB;
  background: linear-gradient(45.3deg,#0055FB -104.95%,#0092FC 50%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12), inset 0px -20px 20px rgba(255, 255, 255, 0.12);
  left: 0;
  top: 50%;
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;

  ${WheelCaption} {
    transform: rotate(-225deg);
  }
`;

const WheelSectorD = styled.div`
  width: 50%;
  height: 50%;
  background: #BF5DFC;
  background: linear-gradient(184deg,#BF5DFC 24%,#8C28C9 100%);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.12), inset 0px 20px 20px rgba(255, 255, 255, 0.12);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;

  ${WheelCaption} {
    transform: rotate(-135deg);
  }
`;

const WheelGlareOuter = styled.div`
  position: absolute;
  width: 75%;
  height: 75%;
  z-index: 30;
  max-width: 75%;
  max-height: 75%;
  top: 50%;
  left: 50%;
`;

const WheelGlare = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
  top: -50%;
  left: -50%;
`;

const WheelActive = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
`;

const WheelBg = styled.img`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

const WheelOuter = styled.div`
  width: 830px;
  height: 830px;
  max-width: 830px;
  max-height: 830px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  
  @media screen and (max-width: 900px) {
    width: 100vw;
    height: 100vw;
  }
  @media screen and (max-width: 600px) {
    width: 140vw;
    height: 140vw;
  }
`;

const WheelEllipseBg = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const WheelSpinContainer = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  min-width: 100px;
  min-height: 100px;
  top: 50%;
  left: 50%;
  z-index: 50;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`;

const WheelSpinButton = styled.img`
  position: relative;
  top: -50%;
  left: -50%;
  height: 100%;
  width: auto;
`;

const WheelSpinLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: -17px;
  left: -34px;
  z-index: 51;
`;

const useAnimationFrame = callback => {
  const requestRef = React.useRef(null);
  const previousTimeRef = React.useRef(null);
  const stopRef = React.useRef(false);

  const animate = time => {
    if (previousTimeRef.current !== null) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }

    if (stopRef && stopRef.current) {
      previousTimeRef.current = null;
      requestRef.current = null;
    } else {
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    }
  }

  React.useEffect(() => {
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    }
  }, []);

  return {
    start: () => {
      requestRef.current = requestAnimationFrame(animate);
    },
    stop: () => {
      stopRef.current = true;
    },
  }
};

const prizes = {
  big: 45,
  average: 135,
  no: 225,
  low: 315,
};

function easeFn (t, b, c, d) {
  return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
}

const useWheel = () => {
  const timeLeftRef = useRef(0);
  const [angle, setAngle] = React.useState(0);
  const startAngle = useRef(100);
  const targetAngle = useRef(0);
  const oneEnd = useRef(null);
  const duration = useRef(0);

  const { start } = useAnimationFrame(deltaTime => {
    if (!duration.current) return;
    timeLeftRef.current = Math.max(timeLeftRef.current - deltaTime, 0);
    if (timeLeftRef.current <= 10) {
      duration.current = 0;
      if (typeof oneEnd.current === 'function') {
        oneEnd.current();
      } else {
        oneEnd.current = null;
      }
      return;
    }
    const x = easeFn(duration.current - timeLeftRef.current, startAngle.current, targetAngle.current, duration.current);

    setAngle(() => {
      return x - 360 * Math.floor(x / 360);
    });
  });

  const spin = (spinTo, setWin) => {
    duration.current = 3000;
    timeLeftRef.current = 3000;
    startAngle.current = angle;
    targetAngle.current = 360 - angle + prizes[spinTo] + 360 * 2;
    oneEnd.current = () => setWin(spinTo);
  };

  useEffect(() => {
    start();
  }, []);

  return { angle, spin };
};

const Wheel = ({
  wheelSpinText,
  wheelTryAgainText,
  wheelMaxBonusTitle,
  wheelMaxBonusText,
  wheelAverageBonusTitle,
  wheelAverageBonusText,
  wheelLowestBonusTitle,
  wheelLowestBonusText,
  setWin
}) => {
  const [spinning, setSpinning] = useState(false);
  const firstTry = useRef(true);
  const { angle, spin } = useWheel();
  const getRandomPrize = () => {
    return Object.keys(prizes)[Math.floor(Math.random()*4)];
  };
  const spinWheel = () => {
    if (spinning) return;
    setSpinning(true);
    spin(firstTry && firstTry.current ? getRandomPrize() : "big", (win) => {
      setSpinning(false);
      setWin(win);
    });
    firstTry.current = false;
  };

  return (
    <WheelOuter>
      <WheelEllipseBg src={ellipse} />
      <WheelContainer>
        <WheelSpinContainer>
          <WheelSpinButton src={spinButton} onClick={spinWheel} />
          <WheelSpinLabel onClick={spinWheel}>{wheelSpinText}</WheelSpinLabel>
        </WheelSpinContainer>
        <WheelActive style={{ transform: `rotate(${angle}deg)` }}>
          <WheelGlareOuter>
            <WheelGlare src={glare} />
          </WheelGlareOuter>
          <WheelSectors>
            <WheelSectorA>
              <WheelCaption>
                <WheelCaptionSum>{wheelMaxBonusTitle}</WheelCaptionSum>
                <WheelCaptionDescription>{wheelMaxBonusText}</WheelCaptionDescription>
              </WheelCaption>
            </WheelSectorA>
            <WheelSectorB>
              <WheelCaption>
                <WheelCaptionSum>{wheelLowestBonusTitle}</WheelCaptionSum>
                <WheelCaptionDescription>{wheelLowestBonusText}</WheelCaptionDescription>
              </WheelCaption>
            </WheelSectorB>
            <WheelSectorC>
              <WheelCaption>
                <WheelCaptionTryAgain>{wheelTryAgainText}</WheelCaptionTryAgain>
              </WheelCaption>
            </WheelSectorC>
            <WheelSectorD>
              <WheelCaption>
                <WheelCaptionSum>{wheelAverageBonusTitle}</WheelCaptionSum>
                <WheelCaptionDescription>{wheelAverageBonusText}</WheelCaptionDescription>
              </WheelCaption>
            </WheelSectorD>
          </WheelSectors>
        </WheelActive>
        <WheelBg src={bg} />
      </WheelContainer>
    </WheelOuter>
  );
};

export default Wheel;
